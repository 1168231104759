import type { RouteLocationNormalized } from 'vue-router'
import { hasRole, targetSecuredUrlPathFromHistory, useUser } from '~/composables/auth/use-customer-info'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  // eslint-disable-next-line node/prefer-global/process
  if (process.server)
    return

  if (!useUser().value) {
    targetSecuredUrlPathFromHistory().value = to.fullPath
    return navigateTo({ name: 'login' })
  }

  if (!hasRole('ADMIN'))
    return navigateTo({ name: 'index' }) // TODO: faq page
})
